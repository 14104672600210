<div *ngIf="isCubejsConnected">
    <div *ngIf="tabIndex !== 3 && tabIndex !== 4">
        <div class="form-group flex flex-wrap items-center mb-0" style="margin-top: 20px">
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report date range label">{{
                    "INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE" | translate
                    }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" [formControl]="startDate" />
                    <input matEndDate placeholder="End date" [formControl]="endDate"
                        (dateChange)="changeEndDate($event, 'Not Activity')" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report chemical name label">Chemical Name</mat-label>
                <input matInput type="text" [formControl]="chemicalNameControl" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Product Category</mat-label>
                <mat-select multiple [formControl]="productTypeControl">
                    <mat-option *ngFor="let product of productTypeFilter" [value]="product">
                        {{ product.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Country</mat-label>
                <mat-select multiple [formControl]="countryControl"
                    (openedChange)="onOpenChange(multiCountrySearch, 'Country')">
                    <input #multiCountrySearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Country" (input)="onInputChange($event, 'Country')" />
                    <mat-option *ngFor="let country of countryFilter" [value]="country.name"
                        (onSelectionChange)="selection($event, 'Country')">
                        {{ country.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Mill</mat-label>
                <mat-select multiple [formControl]="factoryControl"
                    (openedChange)="onOpenChange(multiFactorySearch, 'Mill')">
                    <input #multiFactorySearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Factory" (input)="onInputChange($event, 'Mill')" />
                    <mat-option *ngFor="let factory of factoryFilter" [value]="factory.name"
                        (onSelectionChange)="selection($event, 'Mill')">
                        {{ factory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Used For</mat-label>
                <mat-select multiple [formControl]="usedForControl">
                    <mat-option *ngFor="let usedFor of usedForFilter" [value]="usedFor">
                        {{ usedFor.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Inditex Category</mat-label>
                <mat-select [formControl]="inditexCategoryControl">
                    <!-- <mat-option [value]=""></mat-option> -->
                    <mat-option *ngFor="let inditexCategory of inditexCategoryList" [value]="inditexCategory">
                        {{ inditexCategory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">The List Level</mat-label>
                <mat-select multiple [formControl]="inditexLevelControl">
                    <mat-option *ngFor="let inditexlevel of inditexLevelList" [value]="inditexlevel"
                        (onSelectionChange)="inditexLevelChange($event)">
                        {{ inditexlevel.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Factory Usage</mat-label>
                <mat-select multiple [formControl]="factoryUsageControl">
                    <mat-option *ngFor="let factory of factoryUsage" [value]="factory">
                        {{ factory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">In/Out Scope</mat-label>
                <mat-select [formControl]="scopeCategoryControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let scopeCategory of scopeList" [value]="scopeCategory.value">
                        {{ scopeCategory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="isKontoor">
                <mat-label i18n="Inventories report standards label">Walmart Order</mat-label>
                <mat-select [formControl]="walmartOrderControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let order of walmarOrderList" [value]="order.value">
                        {{ order.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Vendor Name</mat-label>
                <mat-select multiple [formControl]="vendorNameControl" (openedChange)="
                        onOpenChange(multiVendorSearch, 'Vendor Name')
                    ">
                    <input #multiVendorSearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Vendor" (input)="onInputChange($event, 'Vendor Name')" />
                    <mat-option *ngFor="let vendor of vendorNameList" [value]="vendor.name"
                        (onSelectionChange)="selection($event, 'Vendor Name')">
                        {{ vendor.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Vendor ID</mat-label>
                <mat-select multiple [formControl]="vendorIdControl" (openedChange)="
                        onOpenChange(multiVendoridSearch, 'Vendor ID')
                    ">
                    <input #multiVendoridSearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Vendor ID" (input)="onInputChange($event, 'Vendor ID')" />
                    <mat-option *ngFor="let vendor of vendorIdList" [value]="vendor.id"
                        (onSelectionChange)="selection($event, 'Vendor ID')">
                        {{ vendor.id }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Vendor Type</mat-label>
                <mat-select [formControl]="vendorTypeControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let vendor of vendorTypeList" [value]="vendor.type">
                        {{ vendor.type }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Department</mat-label>
                <mat-select [formControl]="departmentControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let dep of departmentList" [value]="dep.name">
                        {{ dep.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width:11vw;">
                <mat-label i18n="Inventories report standards label">Fabric Roll ID/ Order No</mat-label>
                <input matInput type="text" [formControl]="fabricRollIdControl" />
            </mat-form-field>


            <!-- <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Activity Type</mat-label>
                <mat-select multiple [formControl]="activityTypeControl">
                    <mat-option *ngFor="let activity of activityType" [value]="activity">
                        {{activity.name}}</mat-option>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field appearance="fill" *ngIf="isBrandZdhc" style="width: 12vw;">
                <mat-label i18n="Inventories report standards label">ZDHC Gateway Levels</mat-label>
                <mat-select [formControl]="gatewayLevelControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let level of gatewayLevelsList" [value]="level.value">
                        {{level.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="isBrandZdhc" style="width: 13vw;">
                <mat-label i18n="Inventories report standards label">ZDHC Gateway Versions</mat-label>
                <mat-select [formControl]="gatewayVersionsControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let version of gatewayVersionsList" [value]="version.value">
                        {{version.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="!hasScopeCommodities" appearance="fill" class="ml-2">
                <mat-label class="mr-3"><b>Commodities</b></mat-label>
                <mat-slide-toggle color="primary" [formControl]="commoditiesControl"
                    i18n="Inventories report standards label"></mat-slide-toggle>
            </div>
            <div appearance="fill" class="ml-2">
                <mat-label class="mr-3"><b>Brand Scope</b></mat-label>
                <mat-slide-toggle color="primary" [formControl]="brandScopeControl"
                    i18n="Inventories report standards label"></mat-slide-toggle>
            </div>
        </div>
        <div class="mb-3 pie-chart-form">
            <button mat-button class="text-base" color="primary" (click)="applyFilters()">
                <mat-icon [svgIcon]="'feather:filter'"></mat-icon>Apply filters
            </button>
            <button mat-button (click)="clearFilters()">
                <mat-icon [svgIcon]="'mat_outline:clear'"></mat-icon>Clear
                filters
            </button>
        </div>
    </div>
    <div *ngIf="tabIndex === 3">
        <div class="form-group flex flex-wrap items-center mb-0" style="margin-top: 20px">
            <!-- <mat-form-field appearance="fill" style="width: 17vw;">
                <mat-label i18n="Inventories report date range label">{{
                    'INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE' | translate }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" [formControl]="startDate">
                    <input matEndDate placeholder="End date" [formControl]="endDate" (dateChange)="changeEndDate($event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field> -->
            <!-- <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Product Category</mat-label>
                <mat-select multiple [formControl]="productActivityTypeControl">
                    <mat-option *ngFor="let product of productActivityTypeFilter" [value]="product">
                        {{product.name}}</mat-option>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Country</mat-label>
                <mat-select multiple [formControl]="countryActivityControl" (openedChange)="
                        onOpenChange(
                            multiCountryActivitySearch,
                            'Country_Activity'
                        )
                    ">
                    <input #multiCountryActivitySearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Country" (input)="onInputChange($event, 'Country_Activity')" />
                    <mat-option *ngFor="let country of countryActivityFilter" [value]="country.name"
                        (onSelectionChange)="
                            selection($event, 'Country_Activity')
                        ">
                        {{ country.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Mill</mat-label>
                <mat-select multiple [formControl]="factoryActivityControl" (openedChange)="
                        onOpenChange(
                            multiFactoryActivitySearch,
                            'Mill_Activity'
                        )
                    ">
                    <input #multiFactoryActivitySearch type="text" class="ml-4 inputField" autocomplete="off" matInput
                        placeholder="Search By Factory" (input)="onInputChange($event, 'Mill_Activity')" />
                    <mat-option *ngFor="let factory of factoryActivityFilter" [value]="factory.name"
                        (onSelectionChange)="selection($event, 'Mill_Activity')">
                        {{ factory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">In/Out Scope</mat-label>
                <mat-select [formControl]="scopeActivityCategoryControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let scopeCategory of scopeList" [value]="scopeCategory.value">
                        {{ scopeCategory.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mb-3 pie-chart-form">
            <button mat-button class="text-base" color="primary" (click)="applyFilters()">
                <mat-icon [svgIcon]="'feather:filter'"></mat-icon>Apply filters
            </button>
            <button mat-button (click)="clearFilters()">
                <mat-icon [svgIcon]="'mat_outline:clear'"></mat-icon>Clear
                filters
            </button>
        </div>
    </div>
    <div *ngIf="tabIndex == 4">
        <div class="form-group flex flex-wrap items-center mb-0" style="margin-top: 20px">
            <mat-form-field appearance="fill" style="width: 17vw">
                <mat-label i18n="Inventories report date range label">{{
                    "INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE" | translate
                    }}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" [formControl]="startDateActivity" />
                    <input matEndDate placeholder="End date" [formControl]="endDateActivity"
                        (dateChange)="changeEndDate($event, 'Actvity')" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="isBrandZdhc" style="width: 12vw;">
                <mat-label i18n="Inventories report standards label">ZDHC Gateway Levels</mat-label>
                <mat-select [formControl]="gatewayLevelControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let level of gatewayLevelsList" [value]="level.value">
                        {{level.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="isBrandZdhc" style="width: 13vw;">
                <mat-label i18n="Inventories report standards label">ZDHC Gateway Versions</mat-label>
                <mat-select [formControl]="gatewayVersionsControl">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let version of gatewayVersionsList" [value]="version.value">
                        {{version.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mb-3 pie-chart-form">
            <button mat-button class="text-base" color="primary" (click)="applyFilters()">
                <mat-icon [svgIcon]="'feather:filter'"></mat-icon>Apply filters
            </button>
            <button mat-button (click)="clearFilters()">
                <mat-icon [svgIcon]="'mat_outline:clear'"></mat-icon>Clear
                filters
            </button>
        </div>
    </div>
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="selectedTabChanged($event)" class="mt-1">
        <mat-tab label="Supply Chain Performance" id="overview">
            <ng-template matTabContent>
                <mat-grid-list cols="2" rowHeight="16vh">
                    <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="scByCountMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #scByCountMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataSeriesByCount,
                                            'SupplyChainPerformancebyCount'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Overall Supply Chain Performance by
                                Count</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesCompliance"></loading-spinner>
                                <div class="Charts" style="height: 32vh">
                                    <ngx-charts-line-chart (window:resize)="onResize($event)"
                                        [scheme]="{ domain: ['#88bf4d'] }" [results]="timeSeriesCompliance"
                                        [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                                        [showYAxisLabel]="true" [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'"
                                        [showGridLines]="true" [roundDomains]="true" [timeline]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="scByWeightMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #scByWeightMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataSeriesByWeight,
                                            'SupplyChainPerformancebyWeight'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Overall Supply Chain Performance by
                                Weight</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesConsumption"></loading-spinner>
                                <div class="Charts" style="height: 32vh">
                                    <ngx-charts-line-chart (window:resize)="onResize($event)"
                                        [scheme]="{ domain: ['#88bf4d'] }" [results]="timeSeriesConsumption"
                                        [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                                        [showYAxisLabel]="true" [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'"
                                        [showGridLines]="true" [roundDomains]="true" [timeline]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="productCountMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #productCountMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataCategoryByCount,
                                            'ProductCategoryPerformanceByCount'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Product Category Performance by
                                Count</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesCategoryByCount"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 40vh">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                            domain: [
                                                '#88bf4d',
                                                '#509ee3',
                                                'rgb(113, 114, 173)',
                                                'rgb(239, 140, 140)',
                                                'rgb(249, 212, 92)',
                                                'rgb(242, 168, 111)',
                                                'rgb(152, 217, 217)',
                                                'rgb(113, 114, 173)',
                                                'rgb(76, 87, 115)'
                                            ]
                                        }" [results]="timeSeriesCategoryByCount" [xAxis]="true" [yAxis]="true"
                                        [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                        [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'" [showGridLines]="true"
                                        [roundDomains]="true" groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="productByWeightMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #productByWeightMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataCategoryByWeight,
                                            'ProductCategoryPerformanceByWeight'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Product Category Performance by
                                Weight</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesCategoryByWeight"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 40vh">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                            domain: [
                                                '#88bf4d',
                                                '#509ee3',
                                                'rgb(113, 114, 173)',
                                                'rgb(239, 140, 140)',
                                                'rgb(249, 212, 92)',
                                                'rgb(242, 168, 111)',
                                                'rgb(152, 217, 217)',
                                                'rgb(113, 114, 173)',
                                                'rgb(76, 87, 115)'
                                            ]
                                        }" [results]="timeSeriesCategoryByWeight" [xAxis]="true" [yAxis]="true"
                                        [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                        [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'" [showGridLines]="true"
                                        [roundDomains]="true" groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <!-- <mat-grid-tile [colspan]="1" [rowspan]="2">
                        <mat-card>
                            <mat-card-subtitle>Overall Supply Chain Performance (6 Months) by Count
                            </mat-card-subtitle>
                            <mat-card-content>
                                <div class="Charts">
                                    <-- <highcharts-chart 
                                    [Highcharts]="Highcharts"
                        [constructorType]="chartConstructor"
                        [options]="chartOptions" >
                                    </highcharts-chart> ->
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="1" [rowspan]="2"> ->
                        <mat-card>
                            <mat-card-subtitle>Overall Supply Chain Performance (6 Months) by Weight</mat-card-subtitle>
                            <mat-card-content>
                                <div class="Charts">
                                    <-- <highcharts-chart 
                                    [Highcharts]="Highcharts"
                        [constructorType]="chartConstructor"
                        [options]="chartOptions">
                                    </highcharts-chart> ->
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile> -->
                    <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="countryByCountMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #countryByCountMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataOverallByCount,
                                            'CountryPerformancebyCount'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Performance on Country Level by Count
                            </mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesOverallByCount"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 40vh">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                            domain: [
                                                '#88bf4d',
                                                '#509ee3',
                                                'rgb(113, 114, 173)',
                                                'rgb(239, 140, 140)',
                                                'rgb(249, 212, 92)',
                                                'rgb(242, 168, 111)',
                                                'rgb(152, 217, 217)',
                                                'rgb(113, 114, 173)',
                                                'rgb(76, 87, 115)'
                                            ]
                                        }" [results]="timeSeriesOverallByCount" [xAxis]="true" [yAxis]="true"
                                        [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                        [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'" [showGridLines]="true"
                                        [roundDomains]="true" groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="countryByWeightMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #countryByWeightMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataOverallByWeight,
                                            'CountryPerformancebyWeight'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Performance on Country Level by
                                Weight</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesOverallByWeight"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 40vh">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                            domain: [
                                                '#88bf4d',
                                                '#509ee3',
                                                'rgb(113, 114, 173)',
                                                'rgb(239, 140, 140)',
                                                'rgb(249, 212, 92)',
                                                'rgb(242, 168, 111)',
                                                'rgb(152, 217, 217)',
                                                'rgb(113, 114, 173)',
                                                'rgb(76, 87, 115)'
                                            ]
                                        }" [results]="timeSeriesOverallByWeight" [xAxis]="true" [yAxis]="true"
                                        [legend]="true" [showXAxisLabel]="true" [showYAxisLabel]="true"
                                        [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'" [showGridLines]="true"
                                        [roundDomains]="true" groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Performance on factory level">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile [colspan]="6" [rowspan]="2">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="factoryScanMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #factoryScanMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.factoryScanInfoDataList.data,
                                            'FactoryScanInformation'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Factory Scan Information</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="factoryScanInfoDataList"
                                        #factoryScanInfoMatSort="matSort" matSort>
                                        <ng-container matColumnDef="zdhc_aid">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC AID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_aid }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Country
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.country }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="firstScanDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                First Scan Date
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.firstScanDate }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="lastScanMonth">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Scan Month
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.lastScanMonth }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="verifiedCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Verified Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.verifiedCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="unverifiedCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Unverified Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.unverifiedCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="totalCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Total Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.totalCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complianceByCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance By Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.complianceByCount
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complianceByWeight">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance By Weight
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                   formula.complianceByWeight != 'NA' && formula.complianceByWeight != null ? (formula.complianceByWeight | number : '1.2-2') : 'NA'

                                                }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                factoryScanInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: factoryScanInfo
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingFactoryScanInfo"></loading-spinner>
                                </div>
                                <mat-paginator #factoryScanInfoMatPaginator="matPaginator" [pageSizeOptions]="[5]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="2">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="factoryLastScanMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #factoryLastScanMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.factoryLastInfoDataList.data,
                                            'FactoryLastScanInformation'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Last Scan Month Information</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="factoryLastInfoDataList"
                                        #factoryLastScanInfoMatSort="matSort" matSort>
                                        <ng-container matColumnDef="zdhc_aid">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC AID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_aid }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Country
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.country }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="lastScanMonth">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Scan Month
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.lastScanMonth }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="verifiedCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Verified Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.verifiedCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="unverifiedCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Unverified Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.unverifiedCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="totalCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Total Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.totalCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complianceByCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance By Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.complianceByCount
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complianceByWeight">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance By Weight
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                    formula.complianceByWeight != 'NA' && formula.complianceByWeight != null ? (formula.complianceByWeight | number : '1.2-2') : 'NA'

                                                }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                factoryLastScanInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: factoryLastScanInfo
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingFactoryLastScanInfo"></loading-spinner>
                                </div>
                                <mat-paginator #factoryLastScanInfoMatPaginator="matPaginator" [pageSizeOptions]="[5]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="2">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="factoryRatingMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #factoryRatingMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.factoryRatingInfoDataList.data,
                                            'FactoryRatingInformation'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Factory Rating Information</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="factoryRatingInfoDataList"
                                        #factoryRatingInfoMatSort="matSort" matSort>
                                        <ng-container matColumnDef="zdhc_aid">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC AID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_aid }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complianceByCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance By Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.complianceByCount
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="higgSelfPerformance">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg Self Performance
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.higgSelfPerformance | number: "1.2-2"}}
                                            </td>
                                        </ng-container> -->

                                        <!-- <ng-container matColumnDef="higgSelfPerformanceLevel">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg Self Performance
                                                Level</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.HiggSelfLevel}}
                                            </td>
                                        </ng-container> -->

                                        <!-- <ng-container matColumnDef="higgVerifiedPerformance">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg Verified
                                                Performance
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.higgVerifiedPerformance | number: "1.2-2"}}
                                            </td>
                                        </ng-container> -->

                                        <!-- <ng-container matColumnDef="higgVerifiedPerformanceLevel">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg Verified
                                                Performance
                                                Level</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.HiggVerfLevel}}
                                            </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="Higg">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Higg FEM 3.0 Data
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.Higg }}
                                                <a *ngIf="
                                                        formula.Higg === 'Yes'
                                                    " class="linkIcon" [routerLink]="[
                                                        '/higg-certificate/view/',
                                                        formula.higgID
                                                    ]">
                                                    <mat-icon [svgIcon]="
                                                            'mat_outline:link'
                                                        " matTooltip="Go to Higg FEM 3.O Report"></mat-icon>
                                                </a>
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="higgLastUploadDate"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Higg Last Upload Date
                                            </th>
                                            <td
                                                mat-cell
                                                *matCellDef="let formula"
                                            >
                                                {{ formula.higgLastUploadDate | date:'yyyy-MM-dd' }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="wasteWaterResult1">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC WasteWater V 1.1
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.wasteWaterResult1}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="wasteWaterResult2">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC WasteWater V 2.1</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.wasteWaterResult2 | date:'yyyy-MM-dd' }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                factoryRatingInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: factoryRatingInfo
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingFactoryRatingInfo"></loading-spinner>
                                </div>
                                <mat-paginator #factoryRatingInfoMatPaginator="matPaginator" [pageSizeOptions]="[5]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="timeSeriesMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #timeSeriesMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.rawDataCompliancePerformance,
                                            'TimeSeriesPerformance'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Time Series Performance</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingCompliancePerformance"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 42vh">
                                    <ngx-charts-line-chart (window:resize)="onResize($event)"
                                        [scheme]="{ domain: ['#88bf4d','#53ABCD'] }" [results]="compliancePerformance"
                                        [xAxis]="true" [yAxis]="true" [legend]="true" [showXAxisLabel]="true"
                                        [showYAxisLabel]="true" [xAxisLabel]="'Period'" [yAxisLabel]="'Compliance(%)'"
                                        [showGridLines]="true" [roundDomains]="true" [timeline]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="2">
                        <mat-card>
                            <mat-card-subtitle>Overall Supply Chain Performance by
                                Count</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingChartDataByCount"></loading-spinner>
                                <div class="Charts" style="
                                        width: 25vw;
                                        height: 32vh;
                                        align-items: center;
                                    ">
                                    <ngx-charts-advanced-pie-chart id="analytics_pie" class="chart-container"
                                        [results]="chartDataByCount" scheme="vivid" [labels]="true">
                                    </ngx-charts-advanced-pie-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="2">
                        <mat-card>
                            <mat-card-subtitle>Overall Supply Chain Performance by
                                Weight</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingChartDataByWeight"></loading-spinner>
                                <div class="Charts" style="
                                        width: 25vw;
                                        height: 32vh;
                                        align-items: center;
                                    ">
                                    <ngx-charts-advanced-pie-chart id="analytics_pie" class="chart-container"
                                        scheme="vivid" [results]="chartDataByWeight"
                                        [labels]="true"></ngx-charts-advanced-pie-chart>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Chemical Information">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile [colspan]="6" [rowspan]="4">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="chemicalMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #chemicalMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.chemicalInfoDataList.data,
                                            'ChemicalInformation'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Chemical Information</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="chemicalInfoDataList" #chemicalInfoMatSort="matSort"
                                        matSort>
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.id }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="chemicalName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemicalName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway ==
                                                            1 &&
                                                        (isBrandZdhc || isTCP)
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Consumption
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.consumption
                                                }}
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="endingStock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header  >Ending Stock</th>
                                            <td mat-cell *matCellDef="let formula" >
                                                {{formula.endingStock | number: "1.2-2"}}
                                            </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="complianceStatus">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Compliance Status
                                            </th>
                                            <td mat-cell *matCellDef="let formula" [ngClass]="{
                                                    'make-cell-red':
                                                        formula.complianceStatus ===
                                                        'Unverified'
                                                }">
                                                {{ formula.complianceStatus }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryUsage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage in Factory
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryUsage }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                chemicalInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: chemicalInfo
                                            " [ngClass]="{
                                                'make-red':
                                                    row.complianceStatus ===
                                                    'Unverified'
                                            }"></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingChemicalInfo"></loading-spinner>
                                </div>
                                <mat-paginator #chemicalInfoMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="3">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="topUsedMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #topUsedMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.topChemicalsDataList.data,
                                            'OverallTopChemicalsUsed'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle
                                matTooltip="The table shows data independent from Inditex Filters">Overall Top Chemicals
                                Used</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full tops_table unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="topChemicalsDataList" #topChemicalsMatSort="matSort"
                                        matSort>
                                        <ng-container matColumnDef="chemicalName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemicalName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway ==
                                                            1 &&
                                                        (isBrandZdhc || isTCP)
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage (KG)
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.usage
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoriesCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Count of Factories Using
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoriesCount }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                topChemicals;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: topChemicals
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingTopChemicals"></loading-spinner>
                                </div>
                                <mat-paginator #topChemicalsMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="3">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="topManufacturerMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #topManufacturerMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.topManufacturersDataList.data,
                                            'OverallTopManufacturers'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle
                                matTooltip="The table shows data independent from Inditex Filters">Overall Top
                                Manufacturers</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full tops_table unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="topManufacturersDataList"
                                        #topManufacturersMatSort="matSort" matSort>
                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway ==
                                                            1 &&
                                                        (isBrandZdhc || isTCP)
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>BHive Partner</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.partner == 1 ? 'Yes' : 'No'}}
                                            </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="chemicalCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemicalCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage (KG)
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.usage
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                topManufacturers;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: topManufacturers
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingTopManufacturers"></loading-spinner>
                                </div>
                                <mat-paginator #topManufacturersMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="3">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="topUnverifiedChemsMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #topUnverifiedChemsMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.topUnverifiedChemicalsDataList
                                                .data,
                                            'TopUnverifiedChemicals'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Top Unverified Chemicals</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full tops_table unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="
                                            topUnverifiedChemicalsDataList
                                        " #topUnverifiedChemicalsMatSort="matSort" matSort>
                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway ==
                                                            1 &&
                                                        (isBrandZdhc || isTCP)
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="chemicalName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemicalName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage (KG)
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.usage
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoriesCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Count of Factories Using
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoriesCount }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                topUnverifiedChemicals;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: topUnverifiedChemicals
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingTopUnverifiedChemicals"></loading-spinner>
                                </div>
                                <mat-paginator #topUnverifiedChemicalsMatPaginator="matPaginator"
                                    [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="3" [rowspan]="3">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="unverifiedManufacturerMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #unverifiedManufacturerMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this
                                                .topUnverifiedManufacturersDataList
                                                .data,
                                            'TopUnverifiedManufacturer'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Top Unverified Manufacturer</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full tops_table unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="
                                            topUnverifiedManufacturersDataList
                                        " #topUnverifiedManufacturersMatSort="matSort" matSort>
                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway == 1
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>BHive Partner</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.partner == 1 ? 'Yes' : 'No'}}
                                            </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage (KG)
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.usage
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoriesCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Count of Factories Using
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoriesCount }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                topUnverifiedManufacturers;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: topUnverifiedManufacturers
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="
                                            isLoadingTopUnverifiedManufacturers
                                        "></loading-spinner>
                                </div>
                                <mat-paginator #topUnverifiedManufacturersMatPaginator="matPaginator"
                                    [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="3">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="topVerifiedChemsMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #topVerifiedChemsMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.topVerifiedChemicalsDataList
                                                .data,
                                            'TopVerifiedChemicals'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Top Verified Chemicals</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="
                                            topVerifiedChemicalsDataList
                                        " #topVerifiedChemicalsMatSort="matSort" matSort>
                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.manufacturer }}
                                                <mat-icon *ngIf="
                                                        formula.isGateway == 1
                                                    " [ngStyle]="{
                                                        color: '#05AFF0',
                                                        'vertical-align':
                                                            'middle',
                                                        transform: 'scale(0.9)'
                                                    }" matTooltip="This manufacturer is on gateway"
                                                    svgIcon="mat_outline:verified">
                                                </mat-icon>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="chemicalName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemicalName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Usage (KG)
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.usage
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoriesCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Count of Factories Using
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoriesCount }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                topVerifiedChemicals;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: topVerifiedChemicals
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingTopVerifiedChemicals"></loading-spinner>
                                </div>
                                <mat-paginator #topVerifiedChemicalsMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="!isOtto" label="Activity">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile class="dates-tile" [colspan]="6" [rowspan]="1">
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingCreatedAccountsCount"></loading-spinner>
                                <mat-card-title>{{
                                    createdAccountsCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Created Accounts</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingConnectedFactories"></loading-spinner>
                                <mat-card-title>{{
                                    connectedFactories
                                    }}</mat-card-title>
                                <mat-card-subtitle>Connected to
                                    {{ brandName }}</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingScanFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    scanFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Uploaded Inventory</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingCurrentScanFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    currentScanFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Uploaded Inventory in Current
                                    Month</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingIncheckFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    incheckFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Generated Incheck</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingHiggFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    higgFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Uploaded Higg FEM 3.0
                                    Data</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card>
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingWasteWaterFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    wasteWaterFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Uploaded Waste Water
                                    Data</mat-card-subtitle>
                            </div>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="4">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="monitoringMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #monitoringMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportData(
                                            this.activityInfoDataList.data,
                                            'ActivityMonitoring'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Activity Monitoring</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP activity-monitoring">
                                    <table mat-table [dataSource]="activityInfoDataList" #activityInfoMatSort="matSort"
                                        matSort>
                                        <ng-container matColumnDef="supplierID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                SupplierID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.supplierID }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="zdhc_AID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC AID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_AID }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="in_out_scope">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                In/Out Scope
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.in_out_scope }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="email">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Contact Email
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.email }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Country
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.country }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="lastScanDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Scan Date
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.lastScanDate }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="gatewaySubscription">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Subscribed InCheck
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.gatewaySubscription
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="lastScanMonthChemCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Month Chemical Count
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.lastScanMonthChemCount
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="completedScan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marked Inventory as
                                                complete
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.completedScan}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="wasteWaterCount1">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded WasteWater V 1.1 Data
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.wasteWaterCount1}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="wasteWaterCount2">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded WasteWater V 2.1 Data
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.wasteWaterCount2}}
                                            </td>
                                        </ng-container>

                                        <ng-container
                                            matColumnDef="organizationType"
                                        >
                                            <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                mat-sort-header
                                            >
                                                Company Type
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.organizationType }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="connectionType">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Connection Type
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.connectionType }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryActivity">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Type
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryActivity }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="incheckLatestMonth">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last generated InCheck
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.incheckLatestMonth
                                                | date : "yyyy-MM"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="incheckTotalCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Generated InCheck on BHive
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.incheckTotalCount === 0
                                                ? "No"
                                                : "Yes"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="wasteWaterCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Uploaded WasteWater data
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.wasteWaterCount }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="higgTotalCount">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Uploaded Higg FEM data
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.higgTotalCount == 0
                                                ? "No"
                                                : "Yes"
                                                }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                activityInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: activityInfo
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingActivityInfo"></loading-spinner>
                                </div>
                                <mat-paginator #activityInfoMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="isOtto" label="Activity">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile class="dates-tile" [colspan]="6" [rowspan]="1">
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingOttoCreatedAccountsCount"></loading-spinner>
                                <mat-card-title>{{
                                    ottoCreatedAccountsCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Created Accounts</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingOttoConnectedFactories"></loading-spinner>
                                <mat-card-title>{{
                                    ottoConnectedFactories
                                    }}</mat-card-title>
                                <mat-card-subtitle>Connected to
                                    {{ brandName }}</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingOttoNominatedFactories"></loading-spinner>
                                <mat-card-title>{{
                                    ottoNominatedFactories
                                    }}</mat-card-title>
                                <mat-card-subtitle>Nominated Accounts</mat-card-subtitle>
                            </div>
                        </mat-card>
                        <mat-card class="compliance">
                            <div class="info-cards">
                                <loading-spinner *ngIf="isLoadingOttoScanFactoriesCount"></loading-spinner>
                                <mat-card-title>{{
                                    ottoScanFactoriesCount
                                    }}</mat-card-title>
                                <mat-card-subtitle>Uploaded Inventory</mat-card-subtitle>
                            </div>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="6" [rowspan]="4">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="monitoringMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #monitoringMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportOttoActivity(
                                            this.ottoActivityInfoDataList.data,
                                            'Otto Activity Monitoring'
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Activity Monitoring</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="ottoActivityInfoDataList"
                                        #ottoActivityInfoMatSort="matSort" matSort>
                                        <ng-container matColumnDef="facilityID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Facility ID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.facilityID }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="factoryName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Factory Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.factoryName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="comment">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Comment
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                <button (click)="
                                                        openDialog(
                                                            formula.comment
                                                        )
                                                    ">
                                                    <mat-icon class="text-sm" [ngStyle]="{
                                                            color: formula.hasComment
                                                                ? '#05AFF0'
                                                                : ''
                                                        }" svgIcon="mat_solid:comment"></mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Country
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.country }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ottoScope">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Otto Scope
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.ottoScope }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="lastScanDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Last Scan Date
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.lastScanDate
                                                | date : "yyyy-MM-dd"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="isActive">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Active
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.isActive }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="connected">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Connected
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.connected }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="year">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Year
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.year }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="q1">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Q1
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.q1
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="q2">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Q2
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.q2
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="q3">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Q3
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.q3
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="q4">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Q4
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.q4
                                                | number : "1.2-2"
                                                }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="
                                                ottoActivityInfo;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: ottoActivityInfo
                                            "></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingOttoActivityInfo"></loading-spinner>
                                </div>
                                <mat-paginator #ottoActivityInfoMatPaginator="matPaginator" [pageSizeOptions]="[8]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Raw Data">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile [colspan]="6" [rowspan]="4">
                        <mat-card class="h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="activityMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #activityMenu="matMenu" id="more_menu">
                                <button mat-menu-item (click)="
                                        exportRawData(
                                            this.rowDataInfoDataList.data,
                                            isBrandZdhc || isTCP
                                        )
                                    ">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Raw Data</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP raw-data">
                                    <table mat-table [dataSource]="rowDataInfoDataList" #rawInfoMatSort="matSort"
                                        matSort>
                                        <ng-container matColumnDef="zdhc_aid">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC AID
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_aid }}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="higg_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg ID</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.higg_id }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="organization_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header="organization_name">
                                                Factory Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.organization_name}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="scan_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.scan_date}}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="_type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Formula Type</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula._type }}
                                            </td>
                                        </ng-container>



                                        <ng-container matColumnDef="is_compliant">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Status
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.is_compliant }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="chemical_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Chemical Name
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.chemical_name }}
                                            </td>
                                        </ng-container>

                                                    
                                        <ng-container matColumnDef="chemical_manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.chemical_manufacturer}}
                                            </td>
                                        </ng-container>

                                        <ng-container *ngIf="isBrandZdhc || isTCP" matColumnDef="gateway_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gateway_name}}
                                            </td>
                                        </ng-container>

                                        <ng-container *ngIf="isBrandZdhc || isTCP" matColumnDef="gateway_manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Manufacturer
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gateway_manufacturer }}
                                            </td>
                                        </ng-container>

                                        <ng-container *ngIf="isBrandZdhc || isTCP" matColumnDef="gateway_level">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Level</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gateway_level}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="application">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC Use Category (The BHive database)</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.application }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="user_application">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                User Application
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.user_application }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Category
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.category }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.consumption != 'NA' ? (formula.consumption | number : '1.2-2') : 'NA'}}              
                                        </ng-container>

                                        <ng-container matColumnDef="stock_volume">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock Volume</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.stock_volume != 'NA' ? (formula.stock_volume | number : '1.2-2') : 'NA'}}              
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="delivered_quantity">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered Quantity</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.delivered_quantity != 'NA' ? (formula.delivered_quantity | number : '1.2-2') : 'NA'}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="basic_chemical">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commodity</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.basic_chemical }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="productType">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Type</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.productType }}
                                        </ng-container>

                                        <ng-container matColumnDef="lot_number">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Lot Number
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.lot_number }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cas_nr">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Cas Nr
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.cas_nr }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="notes">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Notes
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.notes }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="use_of_ppe">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Use of PPE</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.use_of_ppe }}
                                        </ng-container>

                                        <ng-container matColumnDef="ghs_msds_available">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS MSDS Available</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.ghs_msds_available }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="precautionary_statement">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precautionary
                                                Statement</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.precautionary_statement }}
                                        </ng-container>

                                                    
                                        <ng-container matColumnDef="hazard_statement">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS Hazard Statement</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.hazard_statement}}
                                            </td>
                                        </ng-container>
                                                    
                                        <ng-container matColumnDef="ghs_classification">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS Classification</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.ghs_classification }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="stock_location">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Stock Location
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.stock_location }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="storage_condition">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Storage Condition
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.storage_condition }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="minimum_stock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Minimum Stock</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.minimum_stock != 'NA' ? (formula.minimum_stock | number : '1.2-2') : 'NA'}} 
                                        </ng-container>

                                        <ng-container matColumnDef="maximum_stock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Stock</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.maximum_stock != 'NA' ? (formula.maximum_stock | number : '1.2-2') : 'NA'}}  
                                                
                                        </ng-container>

                                        <ng-container matColumnDef="manufacture_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacture Date</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacture_date | date:'yyyy-MM-dd'  }}
                                        </ng-container>

                                        <ng-container matColumnDef="purchase_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase Date</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.purchase_date | date:'yyyy-MM-dd' }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="expiry_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.expiry_date | date:'yyyy-MM-dd' }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="count_used_for_brand">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Used for  {{this.globalsService.profile["name"].toString()}}
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.count_used_for_brand }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="zdhc_mrsl">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                ZDHC MRSL
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.zdhc_mrsl }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="zdhc_grading">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC Level</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.zdhc_grading }}
                                        </ng-container>

                                         <ng-container matColumnDef="zdhc_mrsl_version">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Version</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.zdhc_mrsl_version }}
                                            </td>
                                        </ng-container> -->
            
                                        <ng-container matColumnDef="zdhc_mrsl_self_declaration">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Self Declaration</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.zdhc_mrsl_self_declaration }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="gots">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                GOTS
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.gots }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="gots_version">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                GOTS Version
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.gots_version }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="eco_passport">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Eco Passport
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.eco_passport }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="bluesign">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Bluesign
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.bluesign }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="bluesign_system_partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Bluesign System Partner
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.bluesign_system_partner
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="green_screen">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Green Screen
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.green_screen }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="green_screen_level">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Green Screen Level
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.green_screen_level }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="c2c">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                C2C
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.c2c }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="inditex_AboveThreeYears">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Inditex Above Three Years
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.inditex_AboveThreeYears
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="inditex_BelowThreeYears">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Inditex Below Three Years
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{
                                                formula.inditex_BelowThreeYears
                                                }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="toxfmd">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Toxfmd
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.toxfmd }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="scivera">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scivera</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.scivera }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="test_report">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Report</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.test_report }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="testing_facility">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Testing Facility
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.testing_facility }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="svhc">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                SVHC
                                            </th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{ formula.svhc }}
                                            </td>
                                        </ng-container>


                                        <!-- <ng-container matColumnDef="noData">
                                            <td *matFooterCellDef [attr.colspan]="rowDataInfoDataList.length">
                                            No records found.
                                            </td>
                                        </ng-container> -->

                                        <tr mat-header-row *matHeaderRowDef="
                                                rowData;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: rowData
                                            "></tr>
                                        <!-- <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="rowData && rowData.data.length > 0"></tr> -->
                                    </table>
                                    <div *ngIf="hasNotData" style="text-align: center">
                                        No records found
                                    </div>
                                    <loading-spinner *ngIf="isLoadingrowDataInfo"></loading-spinner>
                                </div>
                                <mat-paginator #rawMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<div *ngIf="!isCubejsConnected" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-6 sm:p-10">
        <div class="content">
            <div class="flex flex-auto items-center justify-center w-full mb-2 min-h-32">
                <span class="text-3xl font-bold leading-8">
                    Analytics are currently not available due to maintenance.
                    Will be back soon!
                </span>
            </div>
        </div>
    </div>
</div>