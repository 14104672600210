import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'app/globals-services/globals.service';
import { LanguageService } from 'app/language/language.service';
import { DocumentcenterComponent } from '../documentcenter.component';
import { ProductOrderService } from 'app/shared/services/product-order.service';

export interface DocumentData {
  id?: number;
  issueDate?: Date;
  expiryDate?: Date;
  name?: string;
  passFail?: boolean;
  comment?: string;
  category?: string;
  topic?: string;
  file?: File;
  masterId?: string;
  msdsIdentifier?: string;
  verificationDocumentCheck?: Boolean;
  language?: string
}

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {
  document: DocumentData;

  alertMsgs = {};

  expiryDate;
  issueDate;

  testWater: Boolean;
  docConditionCheck: boolean = false;
  verificationDocumentCheck: boolean = false;
  isBluwinDependVerificationNeeded: Boolean = false;
  isFormulaVerified = false
  isVerificationDocumentUpload = false
  hasSds = false
  showVerificationCheckbox = false;
  isTopicWiseUpload: boolean = false;
  dppTemplateSupplierOrgLevelDoc = ProductOrderService.dppTemplateSupplierOrgLevelDoc
  dppTemplateSupplierProductLevelDoc = ProductOrderService.dppTemplateSupplierProductLevelDoc;
  dppTemplateSupplierMaterialFiberLevelDoc = ProductOrderService.dppTemplateSupplierMaterialFiberLevelDoc
  topicForHeader: any;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DocumentcenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public languageService: LanguageService
  ) {
    this.fetchTranslations();
    this.translateService.onLangChange.subscribe(() => {
      // this.loading = true;
      this.fetchTranslations();
    });
  }

  ngOnInit() {


    this.isBluwinDependVerificationNeeded = GlobalsService.isSupplierOfAldiHofer || GlobalsService.isSupplierOfAldiEinkauf || GlobalsService.isSupplierOfSdsScreeningBhive
    //  ||
    //   GlobalsService.isHAndMSupplier ||
    //   GlobalsService.isOttoSupplier;

    this.isFormulaVerified = this.data['isFormulaVerified']
    this.isVerificationDocumentUpload = this.data['isVerificationDocumentUpload']

    this.showVerificationCheckbox = this.data['showVerificationCheckbox']

    if (this.showVerificationCheckbox && this.isVerificationDocumentUpload)
      this.verificationDocumentCheck = true

    if (this.isBluwinDependVerificationNeeded)
      this.verificationDocumentCheck = false


    if (this.data["document"]) {
      this.document = this.data["document"];
      this.testWater = this.data['testWater'];
      this.hasSds = this.data['hasSds']
      this.document['language'] = 'English'
      this.isTopicWiseUpload = ['DPP_INTERNAL_ORGANIZATION_LEVEL', 'DPP_INTERNAL_PRODUCT_LEVEL', 'DPP_INTERNAL_MATERIAL_FIBER_LEVEL', 'DPP_EXTERNAL_ORGANIZATION_LEVEL', 'DPP_EXTERNAL_PRODUCT_LEVEL', 'DPP_EXTERNAL_MATERIAL_FIBER_LEVEL'].includes(this.document?.topic)
      if (this.isTopicWiseUpload) {
        this.topicForHeader = this.trimPrefix(this.data.document['topic'])
      }
    } else {
      this.initDocument();
    }

  }
  trimPrefix(topic: string): string {
    if (topic.startsWith('DPP_INTERNAL_')) {
      return topic.replace('DPP_INTERNAL_', '');
    } else if (topic.startsWith('DPP_EXTERNAL_')) {
      return topic.replace('DPP_EXTERNAL_', '');
    }
    return topic;
  }

  onFileSelectEv(file: File) {

    let uploadTypes = ['pdf', 'PDF'];

    let nameArray = file.name.split('.');
    let type = nameArray[nameArray.length - 1];

    if (!uploadTypes.includes(type)) {
      alert(this.alertMsgs["FILE_TYPE"]);
      this.clearContent();
    } else {
      this.document.file = file;
    }
  }


  addFile(files: FileList) {
    // console.log(files);
    let file: File = files[0];
    let uploadTypes = ['pdf', 'PDF'];
    // console.log(file);
    let nameArray = file.name.split('.');
    let type = nameArray[nameArray.length - 1];
    // console.log(type);
    // console.log(uploadTypes.includes(type));
    if (!uploadTypes.includes(type)) {
      alert(this.alertMsgs["FILE_TYPE"]);
      this.clearContent();
    } else {
      this.document.file = file;
    }

    // console.log(this.document);
  }

  clearContent() {
    var elements = document.getElementsByTagName("input");
    for (var ii = 0; ii < elements.length; ii++) {
      if (elements[ii].type.toLowerCase() == 'file') {
        elements[ii].value = "";
      }
    }
    this.document.file = null;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  upload() {

    if (this.document["name"] == "") {
      alert(this.alertMsgs["DOCUMENT_NAME"]);
    } else if (this.document["category"] == "") {
      alert(this.alertMsgs["DOCUMENT_TYPE"]);
    } else if (!this.isTopicWiseUpload && this.document["passFail"] === undefined && !this.isChemicalDocument()) {
      alert(this.alertMsgs["RESULT"]);
    } else if (!this.document["issueDate"]) {
      alert(this.alertMsgs["ISSUE_DATE"]);
    } else if (!this.document["file"]) {
      alert(this.alertMsgs["FILE_SELECT"]);
    } else if (this.isTopicWiseUpload && !this.document["expiryDate"]) {
      alert(this.alertMsgs["EXPIRY_DATE"]);
    } else if (this.isTopicWiseUpload && !this.document["scope"]) {
      alert(this.alertMsgs["SCOPE"]);
    } else {
      this.document.verificationDocumentCheck = this.verificationDocumentCheck
      this.dialogRef.close(this.document);
    }

  }

  updateExpiryDate(event: MatDatepickerInputEvent<Date>) {
    // console.log(this.expiryDate);
    let expiryDate = new Date(this.expiryDate);
    this.document['expiryDate'] = expiryDate;
    // console.log(expiryDate);
  }

  updateIssueDate(event: MatDatepickerInputEvent<Date>) {
    // console.log(this.expiryDate);
    let issueDate = new Date(this.issueDate);

    this.document['issueDate'] = issueDate;
    // console.log(issueDate);
  }

  initDocument() {
    return { issueDate: undefined, expiryDate: undefined, name: "", passFail: undefined, comment: "", category: "", topic: "", masterId: "-1", msdsIdentifier: "none", file: null, language: 'English' };
  }

  fetchTranslations() {
    this.translateService.get("DOCUMENT_UPLOAD_COMPONENT.ALERTS").subscribe((response) => {
      this.alertMsgs = response;
      // console.log(this.alertMsgs);
    });
  }

  hasFile() {
    // console.log(this.consumption.file);
    return this.document.file;
  }

  isChemicalDocument() {
    // console.log(this.document.masterId != "-1");
    // console.log(this.document.msdsIdentifier != "none");
    return (this.document.masterId != "-1" || this.document.msdsIdentifier != "none");
  }

  onDocCategoryChange(ev) {
    if (this.isBluwinDependVerificationNeeded && this.document.category == 'SDS')
      this.verificationDocumentCheck = false
    else if (this.isVerificationDocumentUpload && ['Test Report', 'Certificate'].includes(this.document.category)) {
      this.verificationDocumentCheck = true
    }
  }
}
