<div class="  inset-0">
    <!-- Main -->
    <div class="flex flex-col flex-auto  h-full  ">

        <div *ngIf="!isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">
            New Feature: Add ZDHC Gateway PIDs!
        </div>

        <div *ngIf="isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">
            新功能：添加 ZDHC Gateway PID！
        </div>

        <br>
        <p *ngIf="!isChineseSystem()" class="pl-6 pr-6   tracking-tight ">

            You can now include ZDHC Gateway PIDs in your inventory uploads, making it easier to create ZDHC InCheck
            reports and reducing errors. To get started, download the NEW Excel template and add the PID in column D
            when uploading your CIL.
            <br> <br>
            Need help?
            <br>
            Check out our guidelines  <a href="https://cloud.goblu.net/s/dRcjRScLMfwAs4a" target="_blank">here</a> and join our ZDHC PID Training:



        </p>
        <ul *ngIf="!isChineseSystem()" class="ml-12 pl-6 pr-6 tracking-tight">
            <li> English Session - Register <a
                    href="https://events.teams.microsoft.com/event/8b306b31-acda-4980-ab64-d4971c7899ad@ee20df82-ca70-4401-9ae5-ead51de3f322"
                    target="_blank">here</a>. </li>
            <li> Turkish Session - Register <a
                    href="https://events.teams.microsoft.com/event/d54475ad-6502-4efc-87aa-86514af399a3@ee20df82-ca70-4401-9ae5-ead51de3f322"
                    target="_blank">here</a>.</li>
            <li> Chinese Session - Register <a
                    href="https://events.teams.microsoft.com/event/11e1c50d-4f69-4508-a2e5-6a663639d275@ee20df82-ca70-4401-9ae5-ead51de3f322"
                    target="_blank">here</a>.</li>
        </ul>





        <p *ngIf="isChineseSystem()" class="pl-6 pr-6   tracking-tight ">

            现在，您可以在上传清单时填入 ZDHC 网关 PID，从而更轻松地创建 ZDHC InCheck 报告并减少错误。开启此功能，务请下载新的 Excel 模板，将 PID 添加到 D 栏，再在上传生成 CIL。
            <br>
            <br>
            如果您需要帮助，有以下二种方式：
        </p>
        <ul *ngIf="isChineseSystem()" class="ml-12 pl-6 pr-6 tracking-tight">
            <li> 点击此处查看我们的 <a
                    href="https://cloud.goblu.net/s/dRcjRScLMfwAs4a"
                    target="_blank">指南</a>。 </li>
            <li> 参加 ZDHC PID 培训 - <a
                    href="https://events.teams.microsoft.com/event/11e1c50d-4f69-4508-a2e5-6a663639d275@ee20df82-ca70-4401-9ae5-ead51de3f322"
                    target="_blank">在此注册</a></li>

        </ul>

        <!--For any questions please contact: <a href="mailto:support@thebhive.net"
            class="contact-email">support@thebhive.net</a>
        </p>
        <br>https://cloud.goblu.net/s/8Ti8GaQPdk847JT
        <ul *ngIf="!isChineseSystem()" class="ml-12 pl-6 pr-6 tracking-tight">
            <li>you update a chemical product’s information in My ChemStore or in Step 6 of the Inventory Upload Flow.
            </li>
            <li>you want to mark a chemical inventory as „complete”.</li>
        </ul> -->



    </div>

    <!-- <p *ngIf="isChineseSystem()" class="pl-6 pr-6  tracking-tight ">
        此功能使您能够通过上传SDS文件来请求对未经验证的化学品进行筛选。请在此<a href="https://cloud.goblu.net/s/8Ti8GaQPdk847JT"
            target="_blank">链接下找到指南</a>。如有任何问题，请联系：
        <a href="mailto:support@thebhive.net" class="contact-email">support@thebhive.net</a>
    </p> -->

    <br>

    <div class="flex" style="justify-content: center;">
        <button class="  mt-4   choose-button " (click)="dialogRef.close(true)">
            {{isChineseSystem()?'确认知悉！':'Got it! '}}
        </button>
        &nbsp; &nbsp;


        <button class="mt-4 btn btn-primary" (click)="dialogRef.close(false)">
            {{isChineseSystem()?'请再次提醒我。':'Remind me again'}}
        </button>
    </div>
</div>
